import React from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import moment from 'moment';
import {
  PaginationState,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table';

import { fetchData, User } from '../lib/dummyData/fetchData';
import Search from './Search';
import IndeterminateCheckbox from './IndeterminateCheckbox';
import { EllipseIcon } from './Icons/EllipseIcon';

const TanstackTable = () => {
  const columns = React.useMemo<ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'name',
        header: ({ table }) => (
          <>
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
            <span>Name</span>
          </>
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'id',
        cell: (info) => info.getValue(),
        header: () => <span>ID</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'email',
        header: () => <span>Email</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'role',
        header: 'Role',
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'dateJoined',
        header: 'Date joined',
        cell: (info) => moment(info.getValue<Date>()).format('MMMM Do YYYY'),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        cell: (info) => {
          const text = info.getValue() === 'active' ? 'Active' : 'On Leave';
          return (
            <span
              className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                info.getValue() === 'active'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800'
              }`}
            >
              {text}
            </span>
          );
        },
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'ellipse',
        cell: () => (
          <button>
            <EllipseIcon />
          </button>
        ),
        header: '',

        footer: (props) => props.column.id,
      },
    ],
    []
  );

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const dataQuery = useQuery({
    queryKey: ['data', pagination],
    queryFn: () => fetchData(pagination),
    placeholderData: keepPreviousData, // don't have 0 rows flash while changing pages/loading next page
  });

  const defaultData = React.useMemo(() => [], []);

  const table = useReactTable({
    data: dataQuery.data?.rows ?? defaultData,
    columns,
    // pageCount: dataQuery.data?.pageCount ?? -1, //you can now pass in `rowCount` instead of pageCount and `pageCount` will be calculated internally (new in v8.13.0)
    rowCount: dataQuery.data?.rowCount, // new in v8.13.0 - alternatively, just pass in `pageCount` directly
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true, // we're doing manual "server-side" pagination
    // getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, we don't need this
    debugTable: true,
  });

  return (
    <div className="">
      {/* <div className="h-2" /> */}
      <div className="mb-4 flex justify-between">
        <Search />
        <div className="flex space-x-2">
          <button
            onClick={() => {}}
            className="rounded border border-neutral-50 p-2"
          >
            Filter
          </button>
          <button
            onClick={() => {}}
            className="rounded border border-neutral-50 p-2"
          >
            Sort by
          </button>
        </div>
      </div>
      <table className="min-w-full border-collapse border-x border-t border-neutral-50">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    className="border-b px-6 py-3 text-left text-sm font-medium leading-4 text-neutral-700"
                    key={header.id}
                    colSpan={header.colSpan}
                  >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className="whitespace-no-wrap border-b px-6 py-4 text-sm leading-5"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <button
          className="rounded border p-1"
          onClick={() => table.firstPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="rounded border p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="rounded border p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="rounded border p-1"
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount().toLocaleString()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            min="1"
            max={table.getPageCount()}
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="w-16 rounded border p-1"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        {dataQuery.isFetching ? 'Loading...' : null}
      </div>
      <div>
        Showing {table.getRowModel().rows.length.toLocaleString()} of{' '}
        {dataQuery.data?.rowCount.toLocaleString()} Rows
      </div>
      <pre>{JSON.stringify(pagination, null, 2)}</pre>
      <pre>{JSON.stringify(table.getSelectedRowModel().rows, null, 2)}</pre>
    </div>
  );
};

export default TanstackTable;
