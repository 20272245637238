import { useMutation, useQuery } from '@tanstack/react-query';
import {
  forgotPassword,
  login,
  resetPassword,
  signup,
  fetchEmailVerification,
} from '../services/authServices';
import {
  LoginType,
  ResetPasswordType,
  SignupType,
} from '@/types/authentication';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  SignupSchema,
  LoginSchema,
  ForgotPasswordSchema,
  ResetPasswordSchema,
} from '../../validations/authSchema';
import { userDetails } from '../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setRefreshToken, setAccessToken } from '../../lib/token';
import { toast } from './../../lib/toast';
interface APIError {
  response: {
    data: {
      message: string;
    };
  };
}
export const useSignUp = () => {
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm<z.infer<typeof SignupSchema>>({
    resolver: zodResolver(SignupSchema),
    mode: 'onBlur',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: signup,
    onSuccess: () => {
      toast({
        message: 'Successfully Signup',
        type: 'success',
        title: 'Success',
      });
      navigate('/notify/activation');
    },
    onError: (err: APIError) => {
      toast({
        message: err.response.data.message || 'Oops! something went wrong',
        position: 'top-center',
        type: 'error',
        title: 'Error',
      });
    },
  });

  const onSignup = handleSubmit(async (userDetails: SignupType) => {
    mutate({ ...userDetails, role: 'Admin' });
  });

  return {
    onSignup,
    isPending,
    register,
    errors,
    watch,
    setValue,
  };
};

export const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    mode: 'onBlur',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      dispatch(userDetails(data.user));
      setRefreshToken(data.accessToken);
      setAccessToken(data.refreshToken);

      if (data?.user?.isVerified) {
        navigate('/dashboard');
        toast({
          message: 'successfully login',
          type: 'success',
          title: 'Success',
        });
      } else {
        navigate('/notify/activation');
        toast({
          message: 'Email not yet verified',
          position: 'top-center',
          type: 'error',
          title: '',
        });
      }
    },
    onError: (err: APIError) => {
      toast({
        message: err.response.data.message || 'Oops! something went wrong',
        position: 'top-center',
        type: 'error',
        title: 'Error',
      });
    },
  });

  const onLogin = handleSubmit(async (user: LoginType) => mutate(user));
  return {
    onLogin,
    isPending,
    register,
    errors,
  };
};

export const useForgotPassword = () => {
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<z.infer<typeof ForgotPasswordSchema>>({
    resolver: zodResolver(ForgotPasswordSchema),
    mode: 'onBlur',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: forgotPassword,
    onSuccess: () => {
      navigate('/notify/reset-password');
    },
  });

  const onResetPasswordRequest = handleSubmit(
    async (email: { email: string }) => mutate(email)
  );
  return {
    onResetPasswordRequest,
    isPending,
    register,
    errors,
  };
};

export const useResetPassword = ({ token }: { token: string }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ResetPasswordType>({
    resolver: zodResolver(ResetPasswordSchema),
    mode: 'onBlur',
  });

  const { mutate, isPending } = useMutation({
    mutationFn: resetPassword,
    onSuccess: (data) => {
      dispatch(userDetails(data.user));
      setRefreshToken(data.accessToken);
      setAccessToken(data.refreshToken);
      if (data?.user?.isVerified) {
        navigate('/dashboard');
      } else {
        navigate('/notify/reset-password');
      }
    },

    onError: (err: APIError) => {
      toast({
        message: err.response.data.message || 'Oops! something went wrong',
        position: 'top-center',
        duration: 10000,
        type: 'error',
        title: 'Error',
      });
    },
  });
  const onResetPassword = handleSubmit(async (details: ResetPasswordType) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { confirmPassword, ...rest } = details;
    mutate({ ...rest, token });
  });
  return {
    onResetPassword,
    isPending,
    register,
    errors,
  };
};

export const useVerifyEmail = (token: string) => {
  const navigate = useNavigate();

  const { data, error, isError, isFetching, isSuccess } = useQuery({
    queryKey: ['verifyEmail', token],
    queryFn: () => fetchEmailVerification(token),
  });
  if (isSuccess) {
    console.log(data);
    navigate('/login');
    toast({
      message: data.message,
      type: 'success',
      title: '',
    });
  }

  if (isError) {
    const axiosError = error as unknown as APIError;
    const errorMessage = axiosError.response?.data?.message;
    navigate('/signup');
    toast({
      message: errorMessage,
      position: 'top-center',
      type: 'error',
      title: 'Error',
      duration: 400,
    });
  }
  return { data, error, isError, isFetching };
};
