import { Loader } from '../components/Icons/Loader';
import { useVerifyEmail } from '../api/hooks/authentication';
import { useSearchParams } from 'react-router-dom';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { isFetching } = useVerifyEmail(token || '');

  return (
    <div className="flex h-screen items-center justify-center bg-indigo-500">
      <Loader
        loading={isFetching}
        header={
          <p className="text-2 mb-2 text-white"> Verifying your email...</p>
        }
      >
        <></>
      </Loader>
    </div>
  );
};

export default VerifyEmail;
