import axios from 'axios';
import {
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  clearTokens,
} from '../../lib/token';

export const refreshAccessToken = async () => {
  try {
    const refreshToken = getRefreshToken();
    const response = await axios.post('/auth/refresh-token', { refreshToken });

    const { accessToken, refreshToken: newRefreshToken } = response.data;
    setAccessToken(accessToken);
    setRefreshToken(newRefreshToken);

    return accessToken;
  } catch (error) {
    clearTokens(); // Clear tokens if refreshing fails
    throw new Error('Session expired');
  }
};
