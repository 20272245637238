import TanstackTable from '../components/TanstackTable';
import BarChat from '../components/BarChart';

const Dashboard = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold">Dashboard</h2>
      <BarChat />
      <TanstackTable />
    </div>
  );
};

export default Dashboard;
