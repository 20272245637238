import React from 'react';
import { SearchIcon } from './Icons/SearchIcon';

const Search = () => {
  return (
    <div className="flex w-full max-w-md items-center rounded-lg border border-gray-300 bg-white shadow-sm">
      <div className="flex items-center px-3">
        <SearchIcon />
      </div>
      <input
        type="text"
        className="w-full rounded-r-lg border-none bg-white p-2 text-gray-700 focus:outline-none"
        placeholder="Search..."
      />
    </div>
  );
};

export default Search;
