import React from 'react';

export const EllipseIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#EDEEF0" />
      <path
        d="M17 10.6665C17 11.2188 16.5523 11.6665 16 11.6665C15.4477 11.6665 15 11.2188 15 10.6665C15 10.1142 15.4477 9.6665 16 9.6665C16.5523 9.6665 17 10.1142 17 10.6665Z"
        fill="black"
      />
      <path
        d="M17 15.9998C17 16.5521 16.5523 16.9998 16 16.9998C15.4477 16.9998 15 16.5521 15 15.9998C15 15.4476 15.4477 14.9998 16 14.9998C16.5523 14.9998 17 15.4476 17 15.9998Z"
        fill="black"
      />
      <path
        d="M16 22.3332C16.5523 22.3332 17 21.8855 17 21.3332C17 20.7809 16.5523 20.3332 16 20.3332C15.4477 20.3332 15 20.7809 15 21.3332C15 21.8855 15.4477 22.3332 16 22.3332Z"
        fill="black"
      />
    </svg>
  );
};
