import AccountLayout from '../components/AccountLayout';
import { Link } from 'react-router-dom';
import { useSignUp } from '../api/hooks/authentication';
import { FormGenerator } from '../components/FormFieldGenerator';
import { SIGN_UP_FORM } from '../constant/form';
import { Loader } from '../components/Icons/Loader';

const Signup = () => {
  const { onSignup, isPending, register, errors, watch, setValue } =
    useSignUp();

  return (
    <AccountLayout>
      <div className="max-w-[448px] px-4 sm:w-[448px] md:px-0">
        <h2 className="text-2xl font-semibold text-neutral-900 sm:text-3xl">
          Create your company account
        </h2>
        <p className="pt-2 text-base font-normal text-neutral-400">
          Enter your details
        </p>
        <form onSubmit={onSignup} className="mx-auto mt-10 max-w-md space-y-5">
          {SIGN_UP_FORM.map((field) => (
            <FormGenerator
              {...field}
              key={field.id}
              register={register}
              errors={errors}
              watch={watch}
              setValue={setValue}
            />
          ))}
          <div>
            <button type="submit" className="button-class">
              <Loader loading={isPending}>Create account</Loader>
            </button>
          </div>
        </form>
        <p className="mt-6 text-center text-base font-normal text-neutral-400">
          Already have an account?{' '}
          <Link
            className="text-base font-medium text-primary-500"
            to={'/login'}
          >
            {' '}
            Login
          </Link>
        </p>
      </div>
    </AccountLayout>
  );
};

export default Signup;
