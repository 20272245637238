import axios from 'axios';
import { getAccessToken, clearTokens } from './../../lib/token';
import { refreshAccessToken } from '../services/refreshAccessToken';

const apiClient = axios.create({
  baseURL: 'https://api.workintercept.com',
  headers: {
    'Content-Type': 'application/json',
  },
});

const protectedApiClient = axios.create({
  baseURL: 'https://api.workintercept.com',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken() || ''}`,
  },
});

// Axios response interceptor for handling 401 errors
protectedApiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return apiClient(originalRequest); // Retry the original request with the new token
      } catch (refreshError) {
        clearTokens(); // Logout user if refresh fails
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export { apiClient, protectedApiClient };
