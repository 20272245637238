import { numberOfEmployeeOptions } from './numberOfEmployment';

export type FormProps = {
  id: string;
  type: 'email' | 'text' | 'password';
  inputType: 'select' | 'input' | 'textarea';
  options?: Option[];
  label?: string;
  placeholder: string;
  name: string;
};
export const SIGN_UP_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: 'Enter company name',
    label: 'Company Name',
    name: 'companyName',
    type: 'text',
  },
  {
    id: '2',
    inputType: 'input',
    placeholder: 'name@company.com',
    label: 'Email',
    name: 'email',
    type: 'text',
  },
  {
    id: '3',
    inputType: 'select',
    placeholder: '',
    label: 'Number of Employees',
    name: 'numberOfEmployees',
    type: 'text',
    options: numberOfEmployeeOptions,
  },
  {
    id: '4',
    inputType: 'input',
    placeholder: 'name@company.com',
    label: 'Password',
    name: 'password',
    type: 'password',
  },
];

export const LOGIN_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: 'name@company.com',
    label: 'Email',
    name: 'email',
    type: 'text',
  },
  {
    id: '2',
    inputType: 'input',
    placeholder: 'Aabbbb1@',
    label: 'Password',
    name: 'password',
    type: 'password',
  },
];

export const RESET_PASSWORD_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: 'Aabbbb1@',
    label: 'New Password',
    name: 'newPassword',
    type: 'password',
  },
  {
    id: '2',
    inputType: 'input',
    placeholder: 'Aabbbb1@',
    label: 'Comfirm Password',
    name: 'confirmPassword',
    type: 'password',
  },
];

export const FORGOT_PASSWORD_FORM: FormProps[] = [
  {
    id: '1',
    inputType: 'input',
    placeholder: 'name@company.com',
    label: 'Email',
    name: 'email',
    type: 'text',
  },
];
