import { Link, Outlet } from 'react-router-dom';

const Notify = () => {
  return (
    <main className="h-screen w-full overflow-scroll">
      <nav className="pt-[53px]">
        <Link to="/" className="ml-6 flex cursor-pointer items-center gap-3">
          <img src="./assets/logo.svg" alt="logo" />
          <p className="text-2xl font-semibold">WorkIntercept</p>
        </Link>
      </nav>
      <Outlet />
    </main>
  );
};

export default Notify;
