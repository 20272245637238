import { UseFormRegister, FieldErrors, FieldValues } from 'react-hook-form';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
} from '../components/ui/select';

import { Label } from './ui/label';
import { Input } from './ui/input';
import { ErrorMessage } from '@hookform/error-message';
import PasswordInput from './PasswordInput';
type FormGeneratorProps = {
  type?: 'text' | 'email' | 'password' | 'number';
  inputType: 'select' | 'input' | 'textarea';
  options?: Option[];
  label?: string;
  placeholder: string;
  register: UseFormRegister<any>;
  name: string;
  errors?: FieldErrors<FieldValues>;
  setValue?: any;
  watch?: any; // Optional if you want to retrieve the value later
};

export const FormGenerator = ({
  inputType,
  options = [],
  label = '',
  placeholder,
  register,
  name,
  errors,
  type,
  setValue = () => {},
  watch = () => {},
}: FormGeneratorProps) => {
  switch (inputType) {
    case 'input':
      return (
        <>
          <Label className="flex flex-col gap-2" htmlFor={`input-${label}`}>
            {label && label}
            {type === 'password' ? (
              <div>
                <PasswordInput
                  type={type}
                  placeholder={placeholder}
                  register={register}
                  name={name}
                  label={label}
                />
                <p className="text-sm font-normal text-neutral-400">
                  Your password must contain 8 or more characters.
                </p>
              </div>
            ) : (
              <Input
                id={`input-${label}`}
                type={type}
                placeholder={placeholder}
                className="input-class"
                {...register(name)}
              />
            )}
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="mt-2 text-red-400">
                  {message === 'Required' ? '' : message}
                </p>
              )}
            />
          </Label>
        </>
      );
    case 'select':
      return (
        <Label htmlFor={`select-${label}`} className="flex flex-col gap-2">
          {label && label}
          <Select
            onValueChange={(value) => setValue(name, value)}
            value={watch(name)}
          >
            <SelectTrigger className="w-full rounded-lg border-[1px] bg-transparent p-3 py-4">
              <span> {watch(name) || 'Select an option'} </span>
            </SelectTrigger>
            <SelectContent>
              {options?.length > 0 &&
                options.map((option) => (
                  <SelectItem value={option.value} key={option.id}>
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <p className="mt-2 text-red-400">
                {message === 'Required' ? '' : message}
              </p>
            )}
          />
        </Label>
      );
    default:
      return <></>;
  }
};
