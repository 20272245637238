import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { SidebarItem } from '../components/SidebarItem';
import Search from '../components/Search';
import { NotificationIcon } from '../components/Icons/NotificationIcon';
import { clearTokens } from '../lib/token';
export interface SidebarData {
  label: string;
  value: string;
  children?: SidebarData[];
}
const sidebarData: SidebarData[] = [
  {
    label: 'Dashboard',
    value: 'dashboard',
  },
  {
    label: 'Employees',
    value: 'employees',
    children: [],
  },
  {
    label: 'Time & Attendance',
    value: 'time_attendance',
  },
];
const lowerSidebarData: SidebarData[] = [
  {
    label: 'Settings',
    value: 'settings',
  },
];

const Dashboard = () => {
  const [activeItem, setActiveItem] = useState('');
  const logout = () => {
    clearTokens();
  };

  return (
    <main className="flex">
      <Sidebar>
        <ul className="flex-1 px-3">
          {sidebarData.map((item) => (
            <span
              key={item.value}
              role="button"
              onClick={() => setActiveItem(item.value)}
            >
              <SidebarItem {...item} active={activeItem === item.value} />
            </span>
          ))}
        </ul>
        <ul className="mb-3 px-3">
          {lowerSidebarData.map((item) => (
            <span
              role="button"
              key={item.value}
              onClick={() => setActiveItem(item.value)}
            >
              <SidebarItem {...item} active={activeItem === item.value} />
            </span>
          ))}
          <span role="button">
            <SidebarItem label={'Logout'} value={''} onClick={logout} />
          </span>
        </ul>
      </Sidebar>
      <section className="w-full">
        <header className="flex w-full items-center border-b border-b-neutral-50 px-6 py-[18px]">
          <p className="text-xl font-medium">
            {' '}
            Welcome,<span className="mr-36 font-semibold"> Hammed</span>
          </p>
          <div className="flex-1">
            <Search />
          </div>
          <NotificationIcon />
        </header>
        <div className="p-6">
          <Outlet />
        </div>
      </section>
    </main>
  );
};

export default Dashboard;
