import CheckEmailCard from '../components/CheckEmailCard';

const ResetPasswordLink = () => {
  return (
    <>
      <CheckEmailCard
        title="Check your email"
        description="We sent you a password reset link. Please be sure to check your spam folder too."
      />
    </>
  );
};

export default ResetPasswordLink;
