import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

const AccountLayout = ({ children }: PropsWithChildren) => {
  return (
    <main className="flex h-screen w-full overflow-scroll">
      <section className="hidden h-full w-[50%] bg-primary-50 pt-16 md:block">
        <Link to="/" className="ml-6 flex cursor-pointer items-center gap-3">
          <img src="./assets/logo.svg" alt="logo" />
          <p className="text-2xl font-semibold">WorkIntercept</p>
        </Link>
        <div className="h-full pt-16">
          <img
            src="./assets/dashboard-image-new.png"
            className="ml-auto h-[90%] w-[90%]"
            alt="dashboard sample"
          />
        </div>
      </section>
      <section className="mx-auto pt-12 sm:ml-4 sm:px-20 sm:pt-36">
        {children}
      </section>
    </main>
  );
};

export default AccountLayout;
