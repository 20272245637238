import { useNavigate } from 'react-router-dom';
import AccountLayout from '../components/AccountLayout';
import RadioGroup from '../components/RadioGroup';
import { Link } from 'react-router-dom';

const Onboarding = () => {
  const navigate = useNavigate();

  const options = [
    {
      id: '1',
      label: 'As an Employer',
      description: 'Sign up and join Work intercept today as an employer ',
    },
    {
      id: '2',
      label: 'As an Employee',
      description: 'Sign up and join Work intercept today as an employee',
    },
  ];

  return (
    <AccountLayout>
      <div className="max-w-[448px] px-4 sm:w-[448px] md:px-0">
        <h2 className="text-2xl font-semibold text-neutral-900 sm:text-3xl">
          How would you like to use Work Intercept?
        </h2>
        <p className="pt-2 text-base font-normal text-neutral-400">
          Please select an option below.
        </p>
        <RadioGroup
          options={options}
          className="mt-8"
          onSelect={(value) => console.log(value)}
          initialValue={options[0].label}
        />
        <button
          onClick={() => navigate('/signup')}
          className="button-class mt-8"
        >
          Continue
        </button>
        <p className="mt-6 text-center text-base font-normal text-neutral-400">
          Already have an account?{' '}
          <Link
            className="text-base font-medium text-primary-500"
            to={'/login'}
          >
            {' '}
            Login
          </Link>
        </p>
      </div>
    </AccountLayout>
  );
};
export default Onboarding;
