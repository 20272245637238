export const NotificationIcon = ({ color = '#704EF1' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.10819 11.9948C1.93098 13.1224 2.72325 13.905 3.69329 14.295C7.41226 15.7903 12.5876 15.7903 16.3065 14.295C17.2766 13.905 18.0688 13.1224 17.8917 11.9948C17.7828 11.3019 17.2443 10.7249 16.8453 10.1615C16.3227 9.41442 16.2708 8.59959 16.2707 7.73268C16.2707 4.38242 13.4632 1.6665 9.99992 1.6665C6.53669 1.6665 3.72919 4.38242 3.72919 7.73268C3.72911 8.59959 3.67719 9.41442 3.15459 10.1615C2.75562 10.7249 2.21709 11.3019 2.10819 11.9948Z"
      stroke="#1E232B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.5 17.5C8.16344 18.0183 9.03958 18.3333 10 18.3333C10.9604 18.3333 11.8366 18.0183 12.5 17.5"
      stroke="#1E232B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
