import { SidebarContext } from '../contexts/sidebarContext';
import { useContext } from 'react';
import { DashboardIcon } from './Icons/DashboardIcon';
import { EmployeeIcon } from './Icons/EmployeeIcon';
import { SettingIcon } from './Icons/SettingIcon';
import { LogoutIcon } from './Icons/LogoutIcon';
import { AttendanceIcon } from './Icons/AttendanceIcon';
import { Link } from 'react-router-dom';

export const SidebarItem = ({
  label,
  active = false,
  value = '',
  hasChidren = false,
  className,
  onClick = () => {},
}: {
  label: string;
  className?: string;
  active?: boolean;
  value: string;
  hasChidren?: boolean;
  onClick?: () => void;
}) => {
  const { expanded } = useContext(SidebarContext);

  const color = active ? '#704EF1' : '#475467';

  const iconMapping: any = {
    dashboard: <DashboardIcon color={color} />,
    employees: <EmployeeIcon color={color} />,
    time_attendance: <AttendanceIcon color={color} />,
    payroll: '',
    schedule: '',
    messages: '',
    settings: <SettingIcon color={color} />,
    logout: <LogoutIcon color={color} />,
  };

  return (
    <li
      className={`group relative my-1 cursor-pointer rounded-md px-4 py-3 font-medium text-neutral-900 transition-colors ${
        active ? 'bg-primary-50' : 'hover:bg-gray-50'
      } ${className}`}
      onClick={onClick}
    >
      <Link to={`/${value}`} className="flex items-center">
        {value && iconMapping[value]}
        <span
          className={`overflow-hidden transition-all ${expanded ? 'ml-3 w-52' : 'w-0'}`}
        >
          {label}
        </span>
      </Link>
    </li>
  );
};
