import { faker } from '@faker-js/faker';

export type Person = {
  firstName: string;
  lastName: string;
  age: number;
  visits: number;
  progress: number;
  status: 'relationship' | 'complicated' | 'single';
  subRows?: Person[];
};

export interface User {
  id?: number;
  name: string;
  email: string;
  role: string;
  imageUrl?: string;
  dateJoined: any;
  status: 'active' | 'onLeave';
  subRows?: User[];
}

const range = (len: number) => {
  const arr: number[] = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newPerson = (): User => {
  return {
    id: faker.number.int({ min: 5, max: 5 }),
    name: faker.person.fullName(),
    imageUrl: faker.image.url(),
    email: faker.internet.email(),
    role: faker.word.noun(),
    dateJoined: faker.date.birthdate(),
    status: faker.helpers.shuffle<User['status']>(['active', 'onLeave'])[0]!,
  };
};

export function makeData(...lens: number[]) {
  const makeDataLevel = (depth = 0): User[] => {
    const len = lens[depth]!;
    return range(len).map((_d): User => {
      return {
        ...newPerson(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      };
    });
  };

  return makeDataLevel();
}

const data = makeData(10000);

export async function fetchData(options: {
  pageIndex: number;
  pageSize: number;
}) {
  // Simulate some network latency
  await new Promise((r) => setTimeout(r, 500));

  return {
    rows: data.slice(
      options.pageIndex * options.pageSize,
      (options.pageIndex + 1) * options.pageSize
    ),
    pageCount: Math.ceil(data.length / options.pageSize),
    rowCount: data.length,
  };
}
