'use client';

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';

import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
} from '../components/ui/chart';

const chartData = [
  { month: 'Jan', onTime: 186, lateTime: 80 },
  { month: 'Feb', onTime: 305, lateTime: 200 },
  { month: 'Mar', onTime: 237, lateTime: 120 },
  { month: 'Apr', onTime: 73, lateTime: 190 },
  { month: 'May', onTime: 209, lateTime: 130 },
  { month: 'Jun', onTime: 214, lateTime: 140 },
];

const chartConfig = {
  onTime: {
    label: 'onTime',
    color: '#2563eb',
  },
  lateTime: {
    label: 'lateTime',
    color: '#60a5fa',
  },
} satisfies ChartConfig;

const OverView = () => {
  return (
    <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
      <BarChart accessibilityLayer data={chartData} width={200}>
        <ChartLegend
          content={<ChartLegendContent />}
          verticalAlign="top"
          align="left"
        />

        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="month"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <YAxis
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          // tickFormatter={(value) => `$${value}`}
        />
        <Bar dataKey="onTime" fill="var(--color-onTime)" radius={4} />
        <Bar dataKey="lateTime" fill="var(--color-lateTime)" radius={4} />
      </BarChart>
    </ChartContainer>
  );
};

export default OverView;
