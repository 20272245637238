import { SidebarContext } from '../contexts/sidebarContext';
import { useState, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ children }: PropsWithChildren) => {
  const [expanded] = useState(true);

  return (
    <aside className="h-screen">
      <nav className="flex h-full w-[272px] flex-col border-r border-neutral-50 bg-white shadow-sm">
        <div className="flex items-center justify-between p-4 pb-2">
          <Link
            to="/"
            className="mb-5 flex cursor-pointer items-center gap-2 px-[12px] py-[9px]"
          >
            <img
              src="./assets/logo.svg"
              className="h-[19.31px] w-[35.5px]"
              alt="logo"
            />
            <p className="text-lg font-semibold">WorkIntercept</p>
          </Link>
          {/* <button
                        onClick={() => setExpanded((curr) => !curr)}
                        className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
                    >
                        {expanded ? <ChevronFirst /> : <ChevronLast />}
                    </button> */}
        </div>

        <SidebarContext.Provider value={{ expanded }}>
          {children}
        </SidebarContext.Provider>
        <div className="mt-3 flex border-t border-t-neutral-50 px-3 py-[23px]">
          <img
            src="https://ui-avatars.com/api/?background=c7d2fe&color=3730a3&bold=true"
            alt=""
            className="h-10 w-10 rounded-full"
          />
          <div
            className={`flex items-center justify-between overflow-hidden transition-all ${expanded ? 'ml-3 w-52' : 'w-0'} `}
          >
            <div className="leading-4">
              <h4 className="font-semibold">Hammed Noibi</h4>
              <span className="text-xs text-gray-600">
                hammed@workintercept.com
              </span>
            </div>
          </div>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
