export const DashboardIcon = ({ color = '#704EF1' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill=" transparent"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.75 10.5C20.0972 10.5 22 8.59721 22 6.25C22 3.90279 20.0972 2 17.75 2C15.4028 2 13.5 3.90279 13.5 6.25C13.5 8.59721 15.4028 10.5 17.75 10.5Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M6.25 10.5C8.59721 10.5 10.5 8.59721 10.5 6.25C10.5 3.90279 8.59721 2 6.25 2C3.90279 2 2 3.90279 2 6.25C2 8.59721 3.90279 10.5 6.25 10.5Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M17.75 22C20.0972 22 22 20.0972 22 17.75C22 15.4028 20.0972 13.5 17.75 13.5C15.4028 13.5 13.5 15.4028 13.5 17.75C13.5 20.0972 15.4028 22 17.75 22Z"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M6.25 22C8.59721 22 10.5 20.0972 10.5 17.75C10.5 15.4028 8.59721 13.5 6.25 13.5C3.90279 13.5 2 15.4028 2 17.75C2 20.0972 3.90279 22 6.25 22Z"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);
