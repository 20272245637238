import { useState } from 'react';
import Eyes from './Icons/Eyes';
import { Input } from './ui/input';
import { UseFormRegister } from 'react-hook-form';

const PasswordInput = ({
  type = 'password',
  placeholder = 'Password',
  register,
  name,
  label,
}: {
  placeholder?: string;
  type?: string;
  register: UseFormRegister<any>;
  name: string;
  label: string;
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative mt-1">
      <Input
        type={showPassword ? 'text' : 'password'}
        id={`input-${label}`}
        className="input-class pr-10"
        placeholder={placeholder}
        {...register(name)}
      />
      <button
        type="button"
        className="absolute inset-y-0 right-0 flex items-center pr-3 shadow-none focus:shadow-none"
        onClick={() => setShowPassword(!showPassword)}
      >
        <Eyes show={showPassword} />
      </button>
    </div>
  );
};

export default PasswordInput;
