import MailBoxIcon from '../assets/mailbox.svg';
import { Link } from 'react-router-dom';

interface CheckEmailCardProp {
  title: string;
  description: string;
}
const CheckEmailCard = ({ title, description }: CheckEmailCardProp) => {
  return (
    <div className="flex h-calc-100-minus-85 items-center justify-center">
      <div className="w-[440px]">
        <img src={MailBoxIcon} alt="mail box" className="mx-auto" />
        <div>
          <h2 className="mt-3 text-center text-2xl font-semibold text-neutral-900 sm:text-3xl">
            {title}
          </h2>
          <p className="mt-1 text-center text-base font-normal text-neutral-400">
            {description}
          </p>
        </div>
        {/* <div>
					<button
						onClick={() => {}}
						className='button-class'>Check your mail</button>
				</div> */}
        <p className="mt-6 text-center text-base font-normal text-neutral-400">
          Didn’t receive an email?{' '}
          <Link className="text-base font-medium text-primary-500" to={'#'}>
            {' '}
            Resend
          </Link>
        </p>
      </div>
    </div>
  );
};

export default CheckEmailCard;
