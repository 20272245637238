import CheckEmailCard from '../components/CheckEmailCard';

const ActivationLink = () => {
  return (
    <>
      <CheckEmailCard
        title="Verify your email"
        description="We sent you an activation link. Please be sure to check your spam folder too."
      />
    </>
  );
};

export default ActivationLink;
