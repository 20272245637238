import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from '../../types/authentication';

const initialState: UserType = {
  _id: '',
  companyId: '',
  companyName: '',
  createdAt: '',
  email: '',
  isVerified: false,
  numberOfEmployees: '',
  role: 'Admin',
  updatedAt: '',
  verificationCode: null,
  verificationCodeExpiry: null,
};

const User = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    userDetails: (state, action: PayloadAction<UserType>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { userDetails } = User.actions;
export default User.reducer;
