export const SearchIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5833 14.5835L18.3333 18.3335"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6667 9.1665C16.6667 5.02437 13.3088 1.6665 9.16667 1.6665C5.02453 1.6665 1.66667 5.02437 1.66667 9.1665C1.66667 13.3087 5.02453 16.6665 9.16667 16.6665C13.3088 16.6665 16.6667 13.3087 16.6667 9.1665Z"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
