import { cn } from '../../lib/utils';
import React from 'react';
type LoaderProps = {
  loading?: boolean;
  children: React.ReactNode;
  color?: string;
  className?: string;
  header?: React.ReactNode | string;
};
export const Loader = ({
  loading = false,
  header,
  children,
  color = 'white',
  className,
}: LoaderProps) => {
  return (
    <div
      className={cn(
        'flex w-full flex-col items-center justify-center',
        className
      )}
    >
      {header}
      {loading ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="24"
          height="24"
        >
          <circle
            cx="50"
            cy="50"
            r="45"
            stroke={color}
            strokeWidth="10"
            fill="none"
            strokeLinecap="round"
            strokeDasharray="283"
            strokeDashoffset="75"
          >
            <animate
              attributeName="stroke-dashoffset"
              values="75;0"
              dur="1.5s"
              repeatCount="indefinite"
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 50 50"
              to="360 50 50"
              dur="2s"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
      ) : (
        <>{children} </>
      )}
    </div>
  );
};
