import React, { useState } from 'react';

interface Option {
  label: string;
  description: string;
}
interface RadioGroupProps {
  options: Option[];
  className?: string;
  onSelect: (v: string) => void;
  initialValue: string;
}

const RadioGroup = ({
  options,
  className,
  onSelect,
  initialValue,
}: RadioGroupProps) => {
  const [selectedOption, setSelectedOption] = useState(initialValue);

  return (
    <div className={`space-y-4 ${className}`}>
      {options.map((option) => (
        <div
          key={option.label}
          className={`flex cursor-pointer items-start rounded-lg px-6 py-4 transition-all ${
            selectedOption === option.label
              ? 'border-2 border-primary-500'
              : 'border border-neutral-200 hover:border-gray-300'
          } `}
          onClick={() => {
            onSelect(option.label);
            setSelectedOption(option.label);
          }}
        >
          <div className="flex-1">
            <div className="text-xl font-medium text-neutral-900">
              {option.label}
            </div>
            <div className="text-base text-neutral-400">
              {option.description}
            </div>
          </div>
          <input
            type="radio"
            name="radio-group"
            value={option.label}
            checked={selectedOption === option.label}
            className={`mr-4 mt-1 shadow-none ${
              selectedOption === option.label
                ? 'accent-primary-500'
                : 'accent-white'
            }`}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
