import { toast as sonner } from 'sonner';

type Position =
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right'
  | 'top-center'
  | 'bottom-center';
type ToastTypes = 'success' | 'error';

interface Toast {
  type: ToastTypes;
  title: string;
  message: string;
  duration?: number;
  position?: Position;
}

export const toast = ({
  type,
  message = '',
  position = 'top-right',
  duration = 4000,
  title,
}: Toast) => {
  return sonner(title, {
    description: message,
    position,
    duration,
    classNames: {
      title:
        type === 'error' ? 'text-red-400 text-2xl' : 'text-green-400 text-2xl',
      description: type === 'error' ? 'text-red-400' : 'text-green-400',
    },
  });
};
