import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import ErrorPage from './error-page';
import DashboardLayout from './pages/DashboardLayout';
import Onboarding from './pages/Onboarding';
import Signup from './pages/Signup';
import Login from './pages/Login';
import ForgetPassword from './pages/ForgetPassword';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import Notify from './pages/Notify';
import ProtectedRoute from './components/ProtectedRoute';
import ActivationEmailSent from './pages/ActivationEmailSent';
import ResetPasswordEmailSent from './pages/ResetPasswordEmailSent';
import VerifyEmail from './pages/VerifyEmail';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Onboarding />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/forgot-password',
    element: <ForgetPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/verify-email',
    element: <VerifyEmail />,
  },
  {
    path: '/notify',
    element: <Notify />,
    children: [
      {
        path: 'activation',
        element: <ActivationEmailSent />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordEmailSent />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <DashboardLayout />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
    ],
  },
]);

const AppRoutes = () => <RouterProvider router={routes} />;

export default AppRoutes;
